import axios from 'axios';
import classNames from 'classnames';
import { CPTCodeRecord } from 'lib/server-utils/nexus-api';
import { DevError, DevLog } from 'lib/utils/logging-utils';
import React, { useEffect, useState } from 'react';
import Autocomplete from 'react-autocomplete';

const fetchCPTCodes = async (cptCodeOrLongDescription: string): Promise<any> => {
  DevLog('CptCodeOrLongDescription ===>', cptCodeOrLongDescription);

  try {
    // Do the search
    const ajaxCPTResult = await axios.get<CPTCodeRecord[]>(
      '/api/nexus/priorauth?' +
        new URLSearchParams([['cptCodeOrLongDescription', cptCodeOrLongDescription]])
    );

    DevLog('ajaxCPTResult', ajaxCPTResult);

    // Empty array if there's no result data
    const data = ajaxCPTResult?.data ?? [];

    DevLog('/api/nexus/priorauth data:', data);

    return data;
  } catch (error) {
    DevError('Error on fetchCPTCodes:', error);
    throw error; // Re-throw the error to maintain the expected return type
  }
};

// types and interfaces
interface PriorAuthProcedureCodeLookupProps {
  searchIcon: string | undefined;
  handleCptCodeAndDescription: (
    cptCode: string,
    expirationDate: string,
    effectiveDate: string,
    comments: string
  ) => void;
  handleCptCodeChange: (value: string) => void;
  errorMessage: string | undefined;
  disableAllFields: boolean;
  clear: boolean;
}

export type ProcedureCodeResultItem = {
  cptCode: string;
  category: string;
  subCategory: string;
  description: string;
  longDescription: string;
  effectiveDate: string;
  expirationDate: string;
  comments: string;
  descriptor: string;
  ruleCount: string;
};

const PriorAuthProcedureCodeLookup: React.FC<PriorAuthProcedureCodeLookupProps> = ({
  searchIcon,
  handleCptCodeAndDescription,
  handleCptCodeChange,
  errorMessage,
  disableAllFields,
  clear,
}) => {
  const [val, setVal] = useState('');
  const [cptCode, setCPTCode] = useState<CPTCodeRecord[]>([]);
  const [loading, setLoading] = useState(false);

  const [showNoRecordsFound, setShowNoRecordsFound] = useState(false);
  const [itemSelected, setItemSelected] = useState<boolean | null>(null);
  const [showError, setShowError] = useState(false);

  // Reset the value when clear is triggered
  useEffect(() => {
    if (clear) {
      setVal('');
      setCPTCode([]);
      setShowError(false);
      setShowNoRecordsFound(false);
      setItemSelected(null);
      handleCptCodeAndDescription('', '', '', '');
      handleCptCodeChange('');
    }
  }, [clear]);

  useEffect(() => {
    setShowNoRecordsFound(false);
    if (val && val.length > 2 && !itemSelected) {
      setLoading(true);
      // sets the cptcodes to empty as fix for Bug :A rendering error occurred: Cannot read properties of null (reading 'ownerDocument').
      setCPTCode([]);
      fetchCPTCodes(val).then((response: CPTCodeRecord[]) => {
        // Take all results
        const filteredCpt: CPTCodeRecord[] = Array.isArray(response) ? response : [];
        DevLog('data===>', response);
        setCPTCode(filteredCpt);
        setLoading(false);
        setShowNoRecordsFound(true);
      });
    } else {
      setCPTCode([]);
    }
  }, [val]);

  const handleBlur = () => {
    if (!itemSelected || val.trim() === '') {
      setShowError(true); // Show error if no valid selection is made
    }
  };

  //Front End UI
  return (
    <>
      <div className="relative flex items-center h-[56px] w-full lg:w-[483px] autocomplete-wrapper">
        <Autocomplete
          value={val}
          items={cptCode}
          getItemValue={(item) => {
            const currentItem = item as ProcedureCodeResultItem;
            const value = currentItem?.cptCode + ' - ' + currentItem?.longDescription;
            return value.length > 46 ? value.slice(0, 43) + '...' : value;
          }}
          shouldItemRender={(item, val) => {
            const currentItem = item as ProcedureCodeResultItem;
            const isItemToRender =
              currentItem.cptCode?.toUpperCase().includes(val?.toUpperCase()) ||
              currentItem.longDescription?.toUpperCase().includes(val?.toUpperCase());
            return isItemToRender;
          }}
          renderMenu={(items) => (
            <div
              className={classNames(
                'dropdown',
                'text-gray-darkest',
                'border',
                'border-gray',
                'w-full', // Ensure the dropdown width matches the parent
                'md:max-h-[348px]',
                'max-h-[494px]',
                'indent-[16px]',
                'my-[12px]',
                'rounded-[4px]',
                'shadow-[0px_2px_8px_0px_rgba(0,0,0,0.08)]',
                'mt-0',
                'mb-0',
                'indent-0',
                'z-30'
              )}
            >
              <div className={classNames('text-gray-darkest', 'rounded-[4px]')}>
                {loading ? (
                  <div className="item">Loading...</div>
                ) : items.length === 0 && showNoRecordsFound && !itemSelected ? (
                  <div className="item">No matching results</div>
                ) : (
                  items
                )}
              </div>
            </div>
          )}
          renderItem={(item, isHighlighted) => {
            const currentItem = item as ProcedureCodeResultItem;
            if (currentItem) {
              return (
                <div
                  key={currentItem.cptCode}
                  className={`item ${isHighlighted ? 'selected-item' : ''}`}
                >{`${currentItem?.cptCode} - ${currentItem?.longDescription}`}</div>
              );
            } else return <></>;
          }}
          onChange={(_event, val) => {
            setItemSelected(false);
            handleCptCodeAndDescription('', '', '', '');
            setVal(val);
            handleCptCodeChange(val);
            setShowError(false);
          }}
          onSelect={(val, item) => {
            handleCptCodeAndDescription(
              item.cptCode,
              item.expirationDate,
              item.effectiveDate,
              item.comments
            );
            setVal(val);
            setItemSelected(true);
            setShowNoRecordsFound(false);
            setShowError(false);
          }}
          inputProps={{
            placeholder: 'Enter Procedure Keyword or CPT Code',
            className: `w-full p-[16px] h-[56px] rounded-[4px] border-2 bg-white focus:border-blue focus:bg-white ${
              showError ? 'border-error' : 'focus:border-blue'
            }`,
            onBlur: handleBlur,
            disabled: disableAllFields,
          }}
        />
        {!loading && (
          // eslint-disable-next-line @next/next/no-img-element
          <img
            src={searchIcon}
            className="absolute top-1/2 transform -translate-y-1/2 cursor-pointer right-[10px] lg:right-[18px] h-[14px] w-[14px] lg:h-[20px] lg:w-[20px]"
          />
        )}
      </div>

      <div
        className={classNames(
          'min-h-[24px]',
          showError === true ? 'text-error visible' : 'invisible'
        )}
      >
        {errorMessage || 'Enter a valid keyword or CPT code'}
      </div>
    </>
  );
};

export default PriorAuthProcedureCodeLookup;
